<template>
  <div class="my-3 mx-4" v-if="reservation">
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.confirmCancellationForm"
    />
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Cancel</div>
        <br />
        <AdminReservationSummary :reservation="reservation" />
        <AdminReservationContact
          header="Customer"
          :contact="reservation.customerContact"
        />
      </div>
    </div>
    <ValidationObserver ref="confirmCancellationForm">
      <form @submit.prevent="confirmCancel">
        <div class="row">
          <div class="col-12"><b>Action:</b> Cancellation</div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <label for="refundOption">
              <b>Refund Options:</b>
            </label>
            <select
              class="form-control form-control-lg mb-2"
              id="refundOption"
              v-model="refundOption"
            >
              <option value="1">Standard Refund</option>
              <option
                value="2"
                v-if="checkPermission('ReservationManagementFullRefund')"
                :disabled="
                  reservation.orderHasMultipleReservations || legacyReservation
                "
                >Full Refund</option
              >
            </select>
          </div>
        </div>
        <div
          class="row mt-3 mb-5"
          v-if="
            (reservation.orderHasMultipleReservations || legacyReservation) &&
              checkPermission('ReservationManagementFullRefund')
          "
        >
          <div class="col-4">
            <span
              >Full refund is not available for this reservation, please contact
              support for full refund.</span
            >
          </div>
        </div>
        <div v-if="!breakdownLoading">
          <div class="row mt-3">
            <div class="col-4">
              <b>Refund Amount:</b>
              <span class="float-right">{{ refundAmount | currency }}</span>
            </div>
          </div>
          <div
            class="row mt-1"
            v-for="(refund, index) in refundBreakdown"
            :key="index"
          >
            <div class="col-4">
              <b class="ml-3">
                {{
                  `Refund to ${refund.paymentType.name}${
                    refund.lastFour ? " " + refund.lastFour : ""
                  }:`
                }}
              </b>
              <span class="float-right">{{
                refund.amount | formatCurrency
              }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-4 text-center my-3">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4" v-if="refundingCash">
            <label for="verifyCashRefund" class="mr-2 float-left pt-1">
              <input
                type="checkbox"
                :value="verifyCashRefund"
                id="verifyCashRefund"
                v-model="verifyCashRefund"
              />
              Verify Cash Amount</label
            >
          </div>
        </div>
        <div class="row mt-3" v-if="refundOption == 1">
          <div class="col-4 mt-3">
            <b>
              <TextInput
                class="mb-2"
                :rules="`required|between:0,${originalCancellationFee}`"
                name="Cancellation Fee:"
                id="cancelFee"
                v-model="cancellationFee"
                @change="feeChange"
                :disabled="
                  !checkPermission('ReservationManagementOverrideOnCancel')
                "
              />
            </b>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-8">
            <b>
              <TextInput
                class="mb-2"
                rules="required|max:3000"
                name="Reason:"
                id="reason"
                v-model="reason"
                rows="3"
              />
            </b>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <button
              class="btn btn-success mr-2"
              type="submit"
              :disabled="submitting || (refundingCash && !verifyCashRefund)"
            >
              CONFIRM CANCELLATION
            </button>
            <button class="btn btn-primary mr-2" type="button" @click="back">
              BACK
            </button>
            <button class="btn btn-primary mr-2" type="button" @click="edit">
              RESERVATION EDIT
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-modal
      id="forceCancellationModal"
      title="Cancellation Failure"
      no-stacking
      no-close-on-backdrop
      size="sm"
      centered
    >
      <h5>
        Refund for this reservation failed because the requested refund amount
        is greater then remaining amount available. Would you still like to
        proceed with the cancellation without refunds?
      </h5>
      <template v-slot:modal-footer="{ ok }">
        <b-button
          class="btn"
          variant="primary"
          @click="forceCancelReservation(ok)"
          >Yes</b-button
        >
        <b-button class="btn" variant="secondary" @click="ok">No</b-button>
      </template>
    </b-modal>
    <AdminOverrideAlertModal
      header="Do you wish to override this fee?"
      modalRef="override-fee-modal"
      :overriddenProp="overriddenFee"
      @cancel="overrideCancel"
    />
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationSummary from "@/components/admin/AdminReservationSummary.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import PricingService from "@/services/PricingService.js";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert";
import { ValidationObserver } from "vee-validate";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

export default {
  name: "AdminReservationDetails",
  title: "Admin - Reservation Cancel",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationSummary,
    TextInput,
    ValidationObserver,
    FormErrorAlert,
    AdminOverrideAlertModal
  },
  props: {
    reservationId: String
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    legacyReservation() {
      return this.reservation.reservationNumber.substring(0, 3) === "RL2";
    },
    refundAmount() {
      let refundAmount = 0;
      //Refund everything except fees unless its a full refund
      this.reservation?.reservationFees?.forEach(fee => {
        if (fee.feeType == "ParkUse" && this.refundOption == 1) {
          refundAmount += fee.amount * fee.quantity;
        } else if (fee.feeType != "Cancellation" && this.refundOption == 2) {
          refundAmount += fee.amount * fee.quantity;
        }
      });
      return this.refundOption == 2
        ? refundAmount
        : refundAmount - this.cancellationFee;
    },
    refundingCash() {
      return this.refundBreakdown?.some(x => x.paymentType.name == "Cash");
    }
  },
  data() {
    return {
      reservation: null,
      refundOption: "1",
      reason: "",
      errors: [],
      fees: [],
      cancellationFee: null,
      originalCancellationFee: null,
      submitting: false,
      overriddenFee: {},
      refundBreakdown: [],
      verifyCashRefund: false,
      breakdownLoading: false
    };
  },
  methods: {
    async getReservationDetail() {
      if (!this.reservationId) return;
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const response = await spotReservationService.getReservationDetail(
        this.reservationId
      );

      if (response.data.status === "Canceled") {
        this.$router
          .push(`/admin/reservation-details/${this.reservationId}`)
          .catch(() => {});
      }

      const reservation = response.data;
      reservation.discounts = reservation.discounts.map(x => x.discountClassId);
      this.reservation = reservation;
      const fees = await this.getFees();
      const cancellationFee =
        fees.find(x => x.feeType === "Cancellation").amount ?? 0;
      this.cancellationFee = cancellationFee.toFixed(2);
      this.originalCancellationFee = cancellationFee.toFixed(2);
      this.fees = fees;
      await this.getRefundBreakdown();
    },
    async getFees() {
      const pricingService = new PricingService(this.tenantId);
      const feeResponse = await pricingService.getFees(
        {
          spotId: this.reservation.spotId,
          startDate: this.reservation.arrivalDate,
          endDate: this.reservation.departureDate,
          numberOfAdults: this.reservation.numberOfAdults,
          numberOfVehicles: this.reservation.numberOfVehicles,
          discounts: this.reservation.discounts,
          customerId: this.reservation.customerId,
          salesChannel: 2
        },
        1
      );
      return feeResponse.data;
    },
    back() {
      this.$router.go(-1);
      this.reservation = {};
      this.$emit("setReservation", null);
    },
    edit() {
      this.$router
        .push(`/admin/reservation-edit-spot/${this.reservationId}`)
        .catch(() => {});
    },
    confirmCancel() {
      this.submitting = true;
      const self = this;
      this.$refs.confirmCancellationForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.confirmCancellationForm.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.confirmCancellationForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          this.submitting = false;
        } else {
          this.errors = [];
          try {
            self.$store.commit("auth/setLoading", true);
            const spotReservationService = new AdminSpotReservationService(
              this.tenantId
            );
            const fees = this.fees;
            fees.forEach(fee => {
              if (fee.feeType === "Cancellation") {
                fee.amount = this.cancellationFee;
              }
            });
            spotReservationService
              .cancelReservation(+this.reservationId, {
                reason: this.reason,
                pricingRequest: {
                  spotId: this.reservation.spotId,
                  startDate: this.reservation.arrivalDate,
                  endDate: this.reservation.departureDate,
                  numberOfAdults: this.reservation.numberOfAdults
                },
                customerId: +this.reservation.customerId,
                reservationId: +this.reservationId,
                feeOverrides: fees,
                refundOption: this.refundOption
              })
              .then(response => {
                if (response.statusCode == "Success") {
                  this.$router
                    .push(
                      `/admin/reservation-cancel-receipt/${response.data.id}`
                    )
                    .catch(() => {});
                } else {
                  if (response.messages[0].startsWith("System.Exception:")) {
                    this.$bvModal.show("forceCancellationModal");
                    self.submitting = false;
                    self.$store.commit("auth/setLoading", false);
                  } else {
                    self.$store.commit("alert/setErrorAlert", {
                      type: "alert-danger",
                      message: "Something went wrong...",
                      layer: "admin"
                    });
                    self.submitting = false;
                    self.$store.commit("auth/setLoading", false);
                  }
                }
              });
          } catch (err) {
            self.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
            this.submitting = false;
            self.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async forceCancelReservation(ok) {
      ok();
      this.$store.commit("auth/setLoading", true);
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const response = await spotReservationService.cancelReservationWithoutRefund(
        +this.reservationId,
        this.reason
      );
      if (response.statusCode == "Success") {
        this.$router
          .push(`/admin/reservation-cancel-receipt/${response.data.id}`)
          .catch(() => {});
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
        this.submitting = false;
        this.$store.commit("auth/setLoading", false);
      }
    },
    overrideCancel(fee) {
      this.cancellationFee = fee.originalAmount;
    },
    async getRefundBreakdown() {
      this.breakdownLoading = true;
      const fees = this.fees;
      fees.forEach(fee => {
        if (fee.feeType === "Cancellation") {
          fee.amount =
            this.cancellationFee == "" || this.refundOption == 2
              ? 0
              : this.cancellationFee;
        }
      });

      const reservationService = new AdminSpotReservationService(this.tenantId);
      const response = await reservationService.getCancellationRefundBreakdown(
        fees,
        this.reservationId,
        this.refundOption
      );
      this.refundBreakdown = response.data;
      this.breakdownLoading = false;
    },
    feeChange(e) {
      this.overriddenFee = {
        name: "Cancellation Fee",
        value: e.target.value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        }),
        originalAmount: this.originalCancellationFee
      };
      this.$bvModal.show("override-fee-modal");
    }
  },
  watch: {
    reservationId() {
      this.getReservationDetail();
    },
    refundAmount() {
      if (this.refundBreakdown.length > 0) {
        this.getRefundBreakdown();
      }
    }
  },
  created() {
    this.getReservationDetail();
  }
};
</script>

<style scoped>
b {
  font-weight: bold;
}
.heading {
  font-size: x-large;
}
</style>
